import React from 'react';
import './App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';

import {Animated} from "react-animated-css";
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Card from 'react-bootstrap/Card';

import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import {db} from './firebase.js';
import MenuItem from './MenuItem.js';



class Burgers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "Burgers",
      showHideBurgers: false,
      showHideBack: false,
      products: []
    }
    this.hideComponent = this.hideComponent.bind(this);
  }

  hideComponent(name) {
    console.log(name);
    switch (name) {
      case "Main":
        this.setState({ showHideBurgers: false,
                        showHideBack: false });
        break;
      case "Burgers":
        this.setState({ showHideBurgers: !this.state.showHideBurgers,
                        showHideBack: !this.state.showHideBack });
        break;
    }
  }

  addToCart = (product) => {
        let cart = localStorage.getItem('cart') 
                      ? JSON.parse(localStorage.getItem('cart')) : {};
        var length = Object.keys(cart).length - 1;
        var l = cart[0] ? JSON.stringify(parseInt(Object.keys(cart)[length]) + 1) : "0";
        cart[l] = (cart[l] ? cart[l]: {});
        cart[l] = product;
        
        localStorage.setItem('cart', JSON.stringify(cart));
      }

  componentDidMount() {
    db.collection("Burgers")
    .get()
    .then(querySnapshot => {
      const data = querySnapshot.docs.map(doc => doc.data());
      console.log(data);
      this.setState({ products: data });
      
      var i = 0;
        querySnapshot.docs.forEach(document => {
          db.collection("Burgers").doc(document.id).collection("Ingredients")
        .get()
        .then(querySnapshot => {

          this.state.products[i].eggs = [];
          this.state.products[i].meat = [];
          this.state.products[i].flavours = [];
          this.state.products[i].sizes = [];

          let products = [...this.state.products];
          let productsIdx = {...products[i]};
          let ing = querySnapshot.docs.map(doc => doc.data());
          productsIdx.ingredients = ing;
          products[i] = productsIdx;
          console.log(products);
          this.setState({products});
          i=i+1;
        });
          
        });

        var j = 0;
        querySnapshot.docs.forEach(document => {
          db.collection("Burgers").doc(document.id).collection("Sides")
        .get()
        .then(querySnapshot => {
          
          this.state.products[j].sides = querySnapshot.docs.map(doc => doc.data());
          j=j+1;
        });
          
        });

        var k = 0;
        querySnapshot.docs.forEach(document => {
          db.collection("Burgers").doc(document.id).collection("Patty")
        .get()
        .then(querySnapshot => {
          
          this.state.products[k].patty = querySnapshot.docs.map(doc => doc.data());
          k=k+1;
        });
          
        });
    });


  }

  componentWillUnmount() {
  }

  render() {
    const { showHideBack, showHideBurgers, products } = this.state;
    
    console.log(this.state);

    return (
        
        <Container fluid className="service-cont">
          <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={true}>
            <Col className="Menu-Cont">
                  <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 250 }}
                  overlay={<Tooltip id="button-tooltip">
                              Tap to view our blissful Burgers!
                            </Tooltip>}
                >
                    
                  <Button className="btn-number" onClick={() => this.hideComponent("Burgers")}>
                    Burgers
                  </Button>
                </OverlayTrigger>
                {showHideBurgers && (<Container fluid className="service-mainbg">
                  <h5>Sunset Burgers are served with your choice of soup, garden salad or fries</h5>
                <h5>Sub a caesar or greek salad or sweet potato fries for $2.99</h5>
                <h5>Make it Poutine for $3.99</h5>
                <h5></h5>
                <h5>All of our burgers are gluten free & can be substituted for a breaded chicken breast or plant based burger</h5>
                <h5>Add another patty to any burger for $5</h5>

                {
                    products.map((product, index) =>
                      <MenuItem 
                        key={index} 
                        sides={product.sides} 
                        eggs={product.eggs} 
                        bread={product.bread} 
                        toast={product.toast} 
                        flavours={product.flavours} 
                        meat={product.meat} 
                        steak={product.steak} 
                        patty={product.patty} 
                        ingredients={product.ingredients} 
                        name={product.name} 
                        price={Math.round((product.price)*100)/100}  
                        description={product.description} 
                        addDesc={product.addDesc} 
                        sideDesc={product.sideDesc}
                        sizes={product.sizes}/>)
                  }

                
                <Row>

          {showHideBack && (<Button className="btn-primary" onClick={() => this.hideComponent("Main")}>Hide Burgers</Button>)}

        </Row>
        </Container>)}
                    </Col>
                
          </Animated>
        </Container>
    );
  }
}

export default Burgers;
