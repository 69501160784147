import React from 'react';
import './App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';

import {Animated} from "react-animated-css";
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Card from 'react-bootstrap/Card';

import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import {db} from './firebase.js';
import MenuItem from './MenuItem.js';


class SunsetSwag extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "SunsetSwag",
      showHideSunsetSwag: false,
      showHideBack: false
    }
    this.hideComponent = this.hideComponent.bind(this);
  }

  hideComponent(name) {
    console.log(name);
    switch (name) {
      case "Main":
        this.setState({ showHideSunsetSwag: false,
                        showHideBack: false });
        break;
      case "SunsetSwag":
        this.setState({ showHideSunsetSwag: !this.state.showHideSunsetSwag,
                        showHideBack: !this.state.showHideBack });
        break;
    }
  }
  addToCart = (product) => {
        let cart = localStorage.getItem('cart') 
                      ? JSON.parse(localStorage.getItem('cart')) : {};
        var length = Object.keys(cart).length - 1;
        var l = cart[0] ? JSON.stringify(parseInt(Object.keys(cart)[length]) + 1) : "0";
        cart[l] = (cart[l] ? cart[l]: {});
        cart[l] = product;
        
        localStorage.setItem('cart', JSON.stringify(cart));
      }

  componentDidMount() {
    db.collection("SunsetSwag")
    .get()
    .then(querySnapshot => {
      const data = querySnapshot.docs.map(doc => doc.data());
      console.log(data);
      this.setState({ products: data });

      var i = 0;
      querySnapshot.docs.forEach(document => {
          this.state.products[i].eggs = [];
          this.state.products[i].alcohol = false;
          this.state.products[i].meat = [];
          this.state.products[i].flavours = [];
          this.state.products[i].patty = [];
          this.state.products[i].ingredients = [];
          this.state.products[i].sides = [];
          i=i+1;
          
        });

      var j = 0;
        querySnapshot.docs.forEach(document => {
          db.collection("SunsetSwag").doc(document.id).collection("Sizes")
        .get()
        .then(querySnapshot => {
          
          this.state.products[j].sizes = querySnapshot.docs.map(doc => doc.data());
          
          j=j+1;
        });
          
        });
    });
  }

  componentWillUnmount() {
  }

  render() {
    const { showHideBack, showHideSunsetSwag, products } = this.state;
    
    return (

      <Container fluid>
        <Container fluid className="service-cont">
          <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={true}>
            <Col className="service-mainbg">
              <Animated className="Menu-Cont" animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={this.props.showHideSunsetSwag}> 
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 250 }}
                  overlay={<Tooltip id="button-tooltip">
                              Tap to view our selection of Sunset Diner swag!
                            </Tooltip>}
                >
                    
                  <Button className="btn-number" onClick={() => this.hideComponent("SunsetSwag")}>
                    Sunset Swag
                  </Button>
                </OverlayTrigger>
                {showHideSunsetSwag && (<Container fluid className="service-mainbg">
                  {
                    products.map((product, index) =>
                      <MenuItem 
                        key={index} 
                        eggs={product.eggs} 
                        bread={product.bread} 
                        toast={product.toast} 
                        meat={product.meat} 
                        patty={product.patty}
                        steak={product.steak} 
                        flavours={product.flavours} 
                        sides={product.sides}
                        ingredients={product.ingredients} 
                        name={product.name} 
                        price={Math.round((product.price)*100)/100}  
                        description={product.description} 
                        addDesc={product.addDesc} 
                        sideDesc={product.sideDesc}
                        alcohol={product.alcohol}
                        sizes={product.sizes}/>)
                  }
                  
                <Row>
          {showHideBack && (<Button className="btn-primary" onClick={() => this.hideComponent("Main")}>Hide Sunset Swag</Button>)}

        </Row>
        </Container>)}
              </Animated>
            </Col>
          </Animated>
        </Container>
      </Container>
    );
  }
}

export default SunsetSwag;
