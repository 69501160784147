import React from 'react';
import './App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';
import ListGroup from 'react-bootstrap/ListGroup';

import {Animated} from "react-animated-css";
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Card from 'react-bootstrap/Card';

import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Loading from './Loading.js';
import { withRouter } from 'react-router-dom';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import {db} from './firebase.js';


class MenuModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      addPrice: 0,
      description: "",
      selected: true,
      ingredients: [],
      liquors: [],
      sizes: [],
      sides: [],
      sidSlct: false,
      flavours: [],
      flvSlct: false,
      meat: [],
      meatSlct: false,
      dressings: [{name: "French", selected: false},
                  {name: "Italian", selected: false},
                  {name: "Balsamic Vinaigrette", selected: false},
                  {name: "Ranch", selected: false},
                  {name: "Poppy Seed", selected: false},
                  {name: "Raspberry Vinaigrette", selected: false},
                  {name: "Blue Cheese", selected: false},
                  {name: "Thousand Island", selected: false}],
      dresSlct: false,
      poutine: [{name: "Stonetown Cheese Curds", selected: false},
              {name: "Shredded Cheese", selected: false}],
      butter: [{name: "Buttered", selected: false},
              {name: "Unbuttered", selected: false}],
      ptneSlct: false,
      toast: [{name: "Well Toasted", selected: false},
              {name: "Toasted", selected: false},
              {name: "Lightly Toasted", selected: false},
              {name: "Untoasted", selected: false}],
      tstSlct: false,
      bread: [{name: "White", selected: false},
              {name: "Brown", selected: false},
              {name: "Multigrain", selected: false, price: 0.2},
              {name: "Rye", selected: false, price: 0.2},
              {name: "Gluten-Free", selected: false, price: 1}],
      brdSlct: false,
      eggs: [],
      eggSlct: false,
      steak: [{name: "Rare", selected: false},
              {name: "Medium", selected: false},
              {name: "Well Done", selected: false},],
      stkSlct: false,
      patty: [],
      patSlct: false,
      unSelected: false
    }
    this.hideModal = this.hideModal.bind(this);
    this.addToCart = this.addToCart.bind(this);
  }

  hideModal =  this.props.onHide;

  addToCart = (product) => {

    if ((this.state.sides[0] && !this.state.sidSlct) || (this.state.flavours[0] && !this.state.flvSlct) || (this.props.meat2 && !this.state.meatSlct) || (this.props.toast && !this.state.tstSlct) || (this.props.bread && !this.state.brdSlct) || (this.state.eggs[0] && !this.state.eggSlct) || (this.props.steak && !this.state.stkSlct) || (this.state.patty[0] && !this.state.patSlct)) {

      console.log(this.state.sides && !this.state.sidSlct);
      console.log(this.state.flavours[0] && !this.state.flvSlct);
      console.log(this.state.patty[0] && !this.state.patSlct);
      console.log(this.state.dressings && !this.state.dresSlct);
      console.log(this.props.steak && !this.state.stkSlct);
      console.log(this.props.meat2 && !this.state.meatSlct);
      console.log(this.props.eggs[0] && !this.state.eggSlct);
      console.log(this.props.toast && !this.state.tstSlct);
      console.log(this.props.bread && !this.state.brdSlct);
      console.log(this.state.unSelected);
      this.setState({unSelected: true});

    } else {

        let cart = localStorage.getItem('cart') 
                      ? JSON.parse(localStorage.getItem('cart')) : {};
        var length = Object.keys(cart).length - 1;
        var l = cart[0] ? JSON.stringify(parseInt(Object.keys(cart)[length]) + 1) : "0";
        cart[l] = (cart[l] ? cart[l]: {});
        cart[l] = product;
        
        localStorage.setItem('cart', JSON.stringify(cart));

        const info = this.createNotification('success', product);
        info();

        this.hideModal();

      }

    }

  createNotification = (type, product) => {
    return () => {
      switch (type) {
        case 'info':
          NotificationManager.info('A new order came in!', 'Order Up!', 3000);
          break;
        case 'success':
          NotificationManager.success(product.product.name+' successfully added to your order!', product.product.name + ' Added!', 3000);
          break;
        case 'warning':
          NotificationManager.warning('Warning message', 'Close after 3000ms', 3000);
          break;
        case 'error':
          NotificationManager.error('Error message', 'Click me!', 5000, () => {
            alert('callback');
          });
          break;
      }
    };
  };

  onCheck = (number) => {
    console.log(number.index);
    console.log(this.state.ingredients[number.index]);
    //this.setState({ingredients[number.index].selected: !this.state.ingredients[number.index].selected});
    console.log(this.state.ingredients);

    // 1. Make a shallow copy of the items
    let ingredients = [...this.state.ingredients];
    // 2. Make a shallow copy of the item you want to mutate
    let ingredient = {...ingredients[number.index]};
    // 3. Replace the property you're intested in
    ingredient.selected = !ingredient.selected;

    var addPrice = this.state.addPrice;
    addPrice = addPrice + (ingredient.price ? ingredient.price : 0);
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    ingredients[number.index] = ingredient;
    // 5. Set the state to our new copy
    this.setState({ingredients, addPrice: addPrice});
    console.log(this.state.ingredients);
  }

  onSideRadio = (number) => {
    console.log(number.idx);
    console.log(this.state.sides[number.idx]);
    //this.setState({ingredients[number.index].selected: !this.state.ingredients[number.index].selected});
    console.log(this.state.sides);

    
    console.log(addPrice);
    // 1. Make a shallow copy of the items
    let sides = [...this.state.sides];
    // 2. Make a shallow copy of the item you want to mutate
    let side = {...sides[number.idx]};
    let allSides = sides.slice();
    allSides.splice(number.idx, 1);
    console.log(allSides);
    console.log(side);
    // 3. Replace the property you're intested in
    side.selected = true;

var addPrice = parseFloat(this.state.addPrice);
    var i=0;
    for (i;i<allSides.length;i++) {

      console.log(allSides[i].price);

      if (allSides[i].selected == true && allSides[i].price != 0) {
          addPrice -= (allSides[i].price ? parseFloat(allSides[i].price) : 0);
console.log(addPrice);
      }
      allSides[i].selected = false;
    }
console.log(addPrice);

console.log(side);
    allSides.splice(number.idx, 0, side);

console.log(allSides);

    
    addPrice = addPrice + (side.price ? side.price : 0);
    console.log(addPrice);
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    sides = allSides;
    // 5. Set the state to our new copy
    addPrice = addPrice ? addPrice : 0;
    console.log(addPrice);
    this.setState({sides: sides, addPrice: addPrice, sidSlct: true}, () => {console.log(this.state);});
    
  }

  onFlavRadio = (number) => {
    console.log(number.idx);
    console.log(this.state.flavours[number.idx]);
    //this.setState({ingredients[number.index].selected: !this.state.ingredients[number.index].selected});
    console.log(this.state.flavours);

    
    console.log(addPrice);
    // 1. Make a shallow copy of the items
    let flavours = [...this.state.flavours];
    // 2. Make a shallow copy of the item you want to mutate
    let flavour = {...flavours[number.idx]};
    let allFlavours = flavours.slice();
    allFlavours.splice(number.idx, 1);
    console.log(allFlavours);
    console.log(flavour);
    // 3. Replace the property you're intested in
    flavour.selected = true;

var addPrice = parseFloat(this.state.addPrice);
    var i=0;
    for (i;i<allFlavours.length;i++) {

      console.log(allFlavours[i].price);

      if (allFlavours[i].selected == true && allFlavours[i].price != 0) {
          addPrice -= (allFlavours[i].price ? parseFloat(allFlavours[i].price) : 0);
console.log(addPrice);
      }
      allFlavours[i].selected = false;
    }
console.log(addPrice);

console.log(flavour);
    allFlavours.splice(number.idx, 0, flavour);

console.log(allFlavours);

    
    addPrice = addPrice + (flavour.price ? flavour.price : 0);
    console.log(addPrice);
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    flavours = allFlavours;
    // 5. Set the state to our new copy
    addPrice = addPrice ? addPrice : 0;
    console.log(addPrice);
    this.setState({flavours: flavours, addPrice: addPrice, flvSlct: true}, () => {console.log(this.state);});
    
  }

  onLiqRadio = (number) => {
    console.log(number.idx);
    console.log(this.state.liquors[number.idx]);
    //this.setState({ingredients[number.index].selected: !this.state.ingredients[number.index].selected});
    console.log(this.state.liquors);

    
    console.log(addPrice);
    // 1. Make a shallow copy of the items
    let liquors = [...this.state.liquors];
    // 2. Make a shallow copy of the item you want to mutate
    let liquor = {...liquors[number.idx]};
    let allLiquors = liquors.slice();
    allLiquors.splice(number.idx, 1);
    console.log(allLiquors);
    console.log(liquor);
    // 3. Replace the property you're intested in
    liquor.selected = true;

var addPrice = parseFloat(this.state.addPrice);
    var i=0;
    for (i;i<allLiquors.length;i++) {

      console.log(allLiquors[i].price);

      if (allLiquors[i].selected == true && allLiquors[i].price != 0) {
          addPrice -= (allLiquors[i].price ? parseFloat(allLiquors[i].price) : 0);
console.log(addPrice);
      }
      allLiquors[i].selected = false;
    }
console.log(addPrice);

console.log(liquor);
    allLiquors.splice(number.idx, 0, liquor);

console.log(allLiquors);

    
    addPrice = addPrice + (liquor.price ? liquor.price : 0);
    console.log(addPrice);
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    liquors = allLiquors;
    // 5. Set the state to our new copy
    addPrice = addPrice ? addPrice : 0;
    console.log(addPrice);
    this.setState({liquors: liquors, addPrice: addPrice, liqSlct: true, alcohol: true}, () => {console.log(this.state);});
    
  }

  onPattyRadio = (number) => {
    console.log(number.idx);
    console.log(this.state.patty[number.idx]);
    //this.setState({ingredients[number.index].selected: !this.state.ingredients[number.index].selected});
    console.log(this.state.patty);

    
    console.log(addPrice);
    // 1. Make a shallow copy of the items
    let patty = [...this.state.patty];
    // 2. Make a shallow copy of the item you want to mutate
    let pat = {...patty[number.idx]};
    let allPatty = patty.slice();
    allPatty.splice(number.idx, 1);
    console.log(allPatty);
    console.log(pat);
    // 3. Replace the property you're intested in
    pat.selected = true;

var addPrice = parseFloat(this.state.addPrice);
    var i=0;
    for (i;i<allPatty.length;i++) {

      console.log(allPatty[i].price);

      if (allPatty[i].selected == true && allPatty[i].price != 0) {
          addPrice -= (allPatty[i].price ? parseFloat(allPatty[i].price) : 0);
console.log(addPrice);
      }
      allPatty[i].selected = false;
    }
console.log(addPrice);

console.log(pat);
    allPatty.splice(number.idx, 0, pat);

console.log(allPatty);

    
    addPrice = addPrice + (pat.price ? pat.price : 0);
    console.log(addPrice);
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    patty = allPatty;
    // 5. Set the state to our new copy
    addPrice = addPrice ? addPrice : 0;
    console.log(addPrice);
    this.setState({patty: patty, addPrice: addPrice, patSlct: true}, () => {console.log(this.state);});
    
  }

  onEggRadio = (number) => {
    console.log(number.idx);
    console.log(this.state.eggs[number.idx]);
    //this.setState({ingredients[number.index].selected: !this.state.ingredients[number.index].selected});
    console.log(this.state.eggs);

    
    console.log(addPrice);
    // 1. Make a shallow copy of the items
    let eggs = [...this.state.eggs];
    // 2. Make a shallow copy of the item you want to mutate
    let egg = {...eggs[number.idx]};
    let allEggs = eggs.slice();
    allEggs.splice(number.idx, 1);
    console.log(allEggs);
    console.log(egg);
    // 3. Replace the property you're intested in
    egg.selected = true;

var addPrice = parseFloat(this.state.addPrice);
    var i=0;
    for (i;i<allEggs.length;i++) {

      console.log(allEggs[i].price);

      if (allEggs[i].selected == true && allEggs[i].price != 0) {
          addPrice -= (allEggs[i].price ? parseFloat(allEggs[i].price) : 0);
console.log(addPrice);
      }
      allEggs[i].selected = false;
    }
console.log(addPrice);

console.log(egg);
    allEggs.splice(number.idx, 0, egg);

console.log(allEggs);

    
    addPrice = addPrice + (egg.price ? egg.price : 0);
    console.log(addPrice);
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    eggs = allEggs;
    // 5. Set the state to our new copy
    addPrice = addPrice ? addPrice : 0;
    console.log(addPrice);
    this.setState({eggs: eggs, addPrice: addPrice, eggSlct: true}, () => {console.log(this.state);});
    
  }

  onToastRadio = (number) => {
    console.log(number.idx);
    console.log(this.state.toast[number.idx]);
    //this.setState({ingredients[number.index].selected: !this.state.ingredients[number.index].selected});
    console.log(this.state.toast);

    
    console.log(addPrice);
    // 1. Make a shallow copy of the items
    let toasts = [...this.state.toast];
    // 2. Make a shallow copy of the item you want to mutate
    let toast = {...toasts[number.idx]};
    let allToasts = toasts.slice();
    allToasts.splice(number.idx, 1);

    // 3. Replace the property you're intested in
    toast.selected = true;

var addPrice = parseFloat(this.state.addPrice);
    var i=0;
    for (i;i<allToasts.length;i++) {


      if (allToasts[i].selected == true && allToasts[i].price != 0) {
          addPrice -= (allToasts[i].price ? parseFloat(allToasts[i].price) : 0);

      }
      allToasts[i].selected = false;
    }

    allToasts.splice(number.idx, 0, toast);


    
    addPrice = addPrice + (toast.price ? toast.price : 0);

    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    toasts = allToasts;
    // 5. Set the state to our new copy
    addPrice = addPrice ? addPrice : 0;
    console.log(addPrice);
    this.setState({toast: toasts, addPrice: addPrice, tstSlct: true}, () => {console.log(this.state);});
    
  }

  onButterRadio = (number) => {
    console.log(number.idx);
    console.log(this.state.butter[number.idx]);
    //this.setState({ingredients[number.index].selected: !this.state.ingredients[number.index].selected});
    
    // 1. Make a shallow copy of the items
    let butters = [...this.state.butter];
    // 2. Make a shallow copy of the item you want to mutate
    let butter = {...butters[number.idx]};
    let allButters = butters.slice();
    allButters.splice(number.idx, 1);

    // 3. Replace the property you're intested in
    butter.selected = true;

var addPrice = parseFloat(this.state.addPrice);
    var i=0;
    for (i;i<allButters.length;i++) {


      if (allButters[i].selected == true && allButters[i].price != 0) {
          addPrice -= (allButters[i].price ? parseFloat(allButters[i].price) : 0);

      }
      allButters[i].selected = false;
    }

    allButters.splice(number.idx, 0, butter);


    
    addPrice = addPrice + (butter.price ? butter.price : 0);

    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    butters = allButters;
    // 5. Set the state to our new copy
    addPrice = addPrice ? addPrice : 0;
    console.log(addPrice);
    this.setState({butter: butters, addPrice: addPrice, tstSlct: true}, () => {console.log(this.state);});
    
  }

    onBreadRadio = (number) => {
    console.log(number.idx);
    console.log(this.state.bread[number.idx]);
    //this.setState({ingredients[number.index].selected: !this.state.ingredients[number.index].selected});
    console.log(this.state.bread);

    
    console.log(addPrice);
    // 1. Make a shallow copy of the items
    let breads = [...this.state.bread];
    // 2. Make a shallow copy of the item you want to mutate
    let bread = {...breads[number.idx]};
    let allBreads = breads.slice();
    allBreads.splice(number.idx, 1);

    // 3. Replace the property you're intested in
    bread.selected = true;

var addPrice = parseFloat(this.state.addPrice);
    var i=0;
    for (i;i<allBreads.length;i++) {


      if (allBreads[i].selected == true && allBreads[i].price != 0) {
          addPrice -= (allBreads[i].price ? parseFloat(allBreads[i].price) : 0);

      }
      allBreads[i].selected = false;
    }

    allBreads.splice(number.idx, 0, bread);


    
    addPrice = addPrice + (bread.price ? bread.price : 0);

    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    breads = allBreads;
    // 5. Set the state to our new copy
    addPrice = addPrice ? addPrice : 0;
    console.log(addPrice);
    this.setState({bread: breads, addPrice: addPrice, brdSlct: true}, () => {console.log(this.state);});
    
  }

  onSteakRadio = (number) => {
    console.log(number.idx);
    console.log(this.state.steak[number.idx]);
    //this.setState({ingredients[number.index].selected: !this.state.ingredients[number.index].selected});
    console.log(this.state.steak);

    
    console.log(addPrice);
    // 1. Make a shallow copy of the items
    let steaks = [...this.state.steak];
    // 2. Make a shallow copy of the item you want to mutate
    let steak = {...steaks[number.idx]};
    let allSteaks = steaks.slice();
    allSteaks.splice(number.idx, 1);

    // 3. Replace the property you're intested in
    steak.selected = true;

var addPrice = parseFloat(this.state.addPrice);
    var i=0;
    for (i;i<allSteaks.length;i++) {


      if (allSteaks[i].selected == true && allSteaks[i].price != 0) {
          addPrice -= (allSteaks[i].price ? parseFloat(allSteaks[i].price) : 0);

      }
      allSteaks[i].selected = false;
    }

    allSteaks.splice(number.idx, 0, steak);


    
    addPrice = addPrice + (steak.price ? steak.price : 0);

    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    steaks = allSteaks;
    // 5. Set the state to our new copy
    addPrice = addPrice ? addPrice : 0;
    console.log(addPrice);
    this.setState({steak: steaks, addPrice: addPrice, stkSlct: true}, () => {console.log(this.state);});
    
  }

  onMeatRadio = (number) => {
    console.log(number.idx);
    console.log(this.state.meat[number.idx]);
    //this.setState({ingredients[number.index].selected: !this.state.ingredients[number.index].selected});
    console.log(this.state.meat);

    
    console.log(addPrice);
    // 1. Make a shallow copy of the items
    let meats = [...this.state.meat];
    // 2. Make a shallow copy of the item you want to mutate
    let meat = {...meats[number.idx]};
    let allMeats = meats.slice();
    allMeats.splice(number.idx, 1);

    // 3. Replace the property you're intested in
    meat.selected = true;

var addPrice = parseFloat(this.state.addPrice);
    var i=0;
    for (i;i<allMeats.length;i++) {


      if (allMeats[i].selected == true && allMeats[i].price != 0) {
          addPrice -= (allMeats[i].price ? parseFloat(allMeats[i].price) : 0);

      }
      allMeats[i].selected = false;
    }

    allMeats.splice(number.idx, 0, meat);


    
    addPrice = addPrice + (meat.price ? meat.price : 0);

    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    meats = allMeats;
    // 5. Set the state to our new copy
    addPrice = addPrice ? addPrice : 0;
    console.log(addPrice);
    this.setState({meat: meats, addPrice: addPrice, meatSlct: true}, () => {console.log(this.state);});
    
  }

  onMeatCheck = (number) => {
    console.log(number.idx);
    console.log(this.state.meat[number.idx]);
    //this.setState({ingredients[number.idx].selected: !this.state.ingredients[number.idx].selected});
    console.log(this.state.meat);

    // 1. Make a shallow copy of the items
    let meats = [...this.state.meat];
    // 2. Make a shallow copy of the item you want to mutate
    let meat = {...meats[number.idx]};
    // 3. Replace the property you're intested in
    meat.selected = !meat.selected;

    var addPrice = this.state.addPrice;
    addPrice = addPrice + (meat.price ? meat.price : 0);
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    meats[number.idx] = meat;
    // 5. Set the state to our new copy
    this.setState({meat: meats, addPrice: addPrice, meatSlct: true});
    console.log(this.state.meat);
  }

    onDressingRadio = (number) => {
    console.log(number.idx);
    console.log(this.state.dressings[number.idx]);
    //this.setState({ingredients[number.index].selected: !this.state.ingredients[number.index].selected});
    console.log(this.state.dressings);

    
    console.log(addPrice);
    // 1. Make a shallow copy of the items
    let dressings = [...this.state.dressings];
    // 2. Make a shallow copy of the item you want to mutate
    let dressing = {...dressings[number.idx]};
    let alldressings = dressings.slice();
    alldressings.splice(number.idx, 1);
    console.log(alldressings);
    console.log(dressing);
    // 3. Replace the property you're intested in
    dressing.selected = true;

var addPrice = parseFloat(this.state.addPrice);
    var i=0;
    for (i;i<alldressings.length;i++) {

      console.log(alldressings[i].price);

      if (alldressings[i].selected == true && alldressings[i].price != 0) {
          addPrice -= (alldressings[i].price ? parseFloat(alldressings[i].price) : 0);
console.log(addPrice);
      }
      alldressings[i].selected = false;
    }
console.log(addPrice);

console.log(dressing);
    alldressings.splice(number.idx, 0, dressing);

console.log(alldressings);

    
    addPrice = addPrice + (dressing.price ? dressing.price : 0);
    console.log(addPrice);
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    dressings = alldressings;
    // 5. Set the state to our new copy
    addPrice = addPrice ? addPrice : 0;
    console.log(addPrice);
    this.setState({dressings: dressings, addPrice: addPrice, dresSlct: true}, () => {console.log(this.state);});
    
  }

  onPoutineCheck = (number) => {
    console.log(number.idx);
    console.log(this.state.poutine[number.idx]);
    //this.setState({ingredients[number.idx].selected: !this.state.ingredients[number.idx].selected});
    console.log(this.state.poutine);

    // 1. Make a shallow copy of the items
    let poutines = [...this.state.poutine];
    // 2. Make a shallow copy of the item you want to mutate
    let poutine = {...poutines[number.idx]};
    // 3. Replace the property you're intested in
    poutine.selected = !poutine.selected;

    var addPrice = this.state.addPrice;
    addPrice = addPrice + (poutine.price ? poutine.price : 0);
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    poutines[number.idx] = poutine;
    // 5. Set the state to our new copy
    this.setState({poutine: poutines, addPrice: addPrice, ptneSlct: true});
    console.log(this.state.poutine);
  }

  onsizeRadio = (number) => {
    console.log(number.idx);
    console.log(this.state.sizes[number.idx]);
    //this.setState({ingredients[number.index].selected: !this.state.ingredients[number.index].selected});
    console.log(this.state.sizes);

    
    console.log(addPrice);
    // 1. Make a shallow copy of the items
    let sizes = [...this.state.sizes];
    // 2. Make a shallow copy of the item you want to mutate
    let size = {...sizes[number.idx]};
    let allsizes = sizes.slice();
    allsizes.splice(number.idx, 1);

    // 3. Replace the property you're intested in
    size.selected = true;

var addPrice = parseFloat(this.state.addPrice);
    var i=0;
    for (i;i<allsizes.length;i++) {


      if (allsizes[i].selected == true && allsizes[i].price != 0) {
          addPrice -= (allsizes[i].price ? parseFloat(allsizes[i].price) : 0);

      }
      allsizes[i].selected = false;
    }

    allsizes.splice(number.idx, 0, size);


    
    addPrice = addPrice + (size.price ? size.price : 0);

    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    sizes = allsizes;
    // 5. Set the state to our new copy
    addPrice = addPrice ? addPrice : 0;
    console.log(addPrice);
    this.setState({sizes: sizes, addPrice: addPrice, sizeSlct: true}, () => {console.log(this.state);});
    
  }

  componentDidMount() {
      let tempSidSlct = this.props.sides[0] ? this.props.sides[0].selected : false;
      this.setState({ingredients: this.props.ingredients, sides: this.props.sides, patty: this.props.patty, flavours: this.props.flavours, eggs: this.props.eggs, meat: this.props.meat, sidSlct: tempSidSlct, sizes: this.props.sizes, alcohol: this.props.alcohol});
      console.log(this.state);

      const liquor = this.props.liquor;

      console.log(liquor);

      if (liquor==true){
        db.collection("Liquors")
        .get()
        .then(querySnapshot => {
          const data = querySnapshot.docs.map(doc => doc.data());
          console.log(data);
          this.setState({ liquors: data });

          var i = 0;
            querySnapshot.docs.forEach(document => {
              
              
            });

        });
      }

      const butter = this.props.butter;

      const toast = this.props.toast;

  

      if (butter==true){
        this.setState({butter: [{name: "Buttered", selected: true},
              {name: "Unbuttered", selected: false}]})
      }

      if (toast==true){
        this.setState({toast: [{name: "Well Toasted", selected: false},
              {name: "Toasted", selected: true},
              {name: "Lightly Toasted", selected: false},
              {name: "Untoasted", selected: false}],
      tstSlct: true})
      }

      
  }

  componentWillUnmount() {
  }

  render() {

    const { name, price, description, addDesc, sideDesc } = this.props;

    const { addPrice, dressings, poutine, unSelected, liquors, butter } = this.state;

    const ingredients = this.state.ingredients;

    const sides = this.state.sides;

    const sizes = this.state.sizes;

    const patty = this.state.patty;

    const flavours = this.state.flavours;

    const toast = this.state.toast;

    const bread = this.state.bread;

    const eggs = this.state.eggs;

    const steak = this.state.steak;

    const meat = this.state.meat;

    const alcohol = this.state.alcohol;

    console.log(liquors);
    
    return (
            <Container fluid>
            
              <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title className="ItemTitle" id="contained-modal-title-vcenter">
                    {name} - ${price}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Card className="ItemCard">
                    <Row className="ItemDeets">
                      <Col>
                      <h4 className="ItemTitle">{sideDesc}</h4>
                      <h4 className="ItemTitle">{addDesc}</h4>
                        <h4 className="ItemSubtitle">
                          {description}
                        </h4>
                        
                        
                      </Col>
                    </Row>
                    <Card.Body>
                    </Card.Body>
                  </Card>
                </Modal.Body>
                <Modal.Footer>
                  
                
                </Modal.Footer>
              </Modal>
              
          </Container>
    );
  }
}

export default withRouter(MenuModal);
