import React from 'react';
import './App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';
import {Animated} from "react-animated-css";
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import {db} from './firebase.js';
import MenuItem from './MenuItem.js';
import OnTap from './OnTap.js';
import TallBoys from './TallBoys.js';
import BottledBeer from './BottledBeer.js';
import Coolers from './Coolers.js';
import Wines from './Wines.js';
import Liquors from './Liquors.js';
import Local from './Local.js';
import MysteryBags from './MysteryBags.js';
import Cocktails from './Cocktails.js';


class Alcohol extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "Alcohol",
      showHideAlcohol: false,
      showHideBack: false
    }
    this.hideComponent = this.hideComponent.bind(this);
  }

  hideComponent(name) {
    console.log(name);
    switch (name) {
      case "Main":
        this.setState({ showHideAlcohol: false,
                        showHideBack: false });
        break;
      case "Alcohol":
        this.setState({ showHideAlcohol: !this.state.showHideAlcohol,
                        showHideBack: !this.state.showHideBack });
        break;
    }
  }
  addToCart = (product) => {
        let cart = localStorage.getItem('cart') 
                      ? JSON.parse(localStorage.getItem('cart')) : {};
        var length = Object.keys(cart).length - 1;
        var l = cart[0] ? JSON.stringify(parseInt(Object.keys(cart)[length]) + 1) : "0";
        cart[l] = (cart[l] ? cart[l]: {});
        cart[l] = product;
        
        localStorage.setItem('cart', JSON.stringify(cart));
      }

  componentDidMount() {
    
  }

  componentWillUnmount() {
  }

  render() {
    const { showHideBack, showHideAlcohol, products } = this.state;
    
    return (

      <Container fluid>
        <Container fluid className="service-cont">
          <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={true}>
            <Col className="service-mainbg">
              <Animated className="Menu-Cont" animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={this.props.showHideAlcohol}> 
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 250 }}
                  overlay={<Tooltip id="button-tooltip">
                              Must be 19+ to order
                            </Tooltip>}
                >
                    
                  <Button className="btn-number" onClick={() => this.hideComponent("Alcohol")}>
                    Alcohol
                  </Button>
                  </OverlayTrigger>
                {showHideAlcohol && (<Container fluid className="service-mainbg">

                  <TallBoys/>
                  <BottledBeer/>
                  <Coolers/>
                  <Wines/>
                <Row>
          {showHideBack && (<Button className="btn-primary" onClick={() => this.hideComponent("Main")}>Hide Alcohol</Button>)}

        </Row>
        </Container>)}
              </Animated>
            </Col>
          </Animated>
        </Container>
      </Container>
    );
  }
}

export default Alcohol;
