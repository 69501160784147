import React from 'react';
import './App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';

import {Animated} from "react-animated-css";
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Card from 'react-bootstrap/Card';

import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import {db} from './firebase.js';
import MenuItem from './MenuItem.js';



class Sandwiches extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "Sandwiches",
      showHideSandwiches: false,
      showHideBack: false
    }
    this.hideComponent = this.hideComponent.bind(this);
  }

  hideComponent(name) {
    console.log(name);
    switch (name) {
      case "Main":
        this.setState({ showHideSandwiches: false,
                        showHideBack: false });
        break;
      case "Sandwiches":
        this.setState({ showHideSandwiches: !this.state.showHideSandwiches,
                        showHideBack: !this.state.showHideBack });
        break;
    }
  }

  addToCart = (product) => {
        let cart = localStorage.getItem('cart') 
                      ? JSON.parse(localStorage.getItem('cart')) : {};
        var length = Object.keys(cart).length - 1;
        var l = cart[0] ? JSON.stringify(parseInt(Object.keys(cart)[length]) + 1) : "0";
        cart[l] = (cart[l] ? cart[l]: {});
        cart[l] = product;
        
        localStorage.setItem('cart', JSON.stringify(cart));
      }

  componentDidMount() {
    db.collection("Sandwiches")
    .get()
    .then(querySnapshot => {
      const data = querySnapshot.docs.map(doc => doc.data());
      console.log(data);
      this.setState({ products: data });
      var i = 0;
        querySnapshot.docs.forEach(document => {
          db.collection("Sandwiches").doc(document.id).collection("Ingredients")
        .get()
        .then(querySnapshot => {
          
          this.state.products[i].eggs = [];
          this.state.products[i].meat = [];
          this.state.products[i].sizes = [];
          this.state.products[i].patty = [];
          this.state.products[i].flavours = [];
          this.state.products[i].ingredients = querySnapshot.docs.map(doc => doc.data());
          i=i+1;
        });
          
        });

        var j = 0;
        querySnapshot.docs.forEach(document => {
          db.collection("Sandwiches").doc(document.id).collection("Sides")
        .get()
        .then(querySnapshot => {
          
          this.state.products[j].sides = querySnapshot.docs.map(doc => doc.data());
          j=j+1;
        });
          
        });
    });
  }

  componentWillUnmount() {
  }

  render() {
    const { showHideBack, showHideSandwiches, products } = this.state;
    
    return (

        
        <Container fluid className="service-cont">
          <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={true}>
            <Col className="service-mainbg">
                  <Animated className="Menu-Cont" animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={this.props.showHideSandwiches}> 
                  <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 250 }}
                  overlay={<Tooltip id="button-tooltip">
                              Tap to look at our swell Sandwiches & Wraps!
                            </Tooltip>}
                >
                    
                  <Button className="btn-number" onClick={() => this.hideComponent("Sandwiches")}>
                    Sandwiches & Wraps
                  </Button>
                </OverlayTrigger>
                {showHideSandwiches && (<Container fluid className="service-mainbg">
                  <h5>Sandwiches & wraps are served with your choice of soup, garden salad or fries</h5>
                <h5>Sub a caesar or greek salad or sweet potato fries for $2.49</h5>
                <h5>Make it Poutine for $2.99</h5>
                <h5>We have gluten free bread!</h5>
                {
                    products.map((product, index) =>
                      <MenuItem 
                        key={index} 
                        steak={product.steak} 
                        eggs={product.eggs} 
                        flavours={product.flavours} 
                        meat={product.meat} 
                        sides={product.sides} 
                        patty={product.patty}
                        bread={product.bread} 
                        toast={product.toast} 
                        ingredients={product.ingredients} 
                        name={product.name} 
                        price={Math.round((product.price)*100)/100}
                        description={product.description} 
                        addDesc={product.addDesc} 
                        sideDesc={product.sideDesc}
                        sizes={product.sizes}/>)
                  }

                
                <Row>
          {showHideBack && (<Button className="btn-primary" onClick={() => this.hideComponent("Main")}>Hide Sandwiches</Button>)}

        </Row>
        </Container>)}
                  </Animated>
                    </Col>
                
          </Animated>
        </Container>
    );
  }
}

export default Sandwiches;
