import React from 'react';
import './App.css';
import Appetizers from './Appetizers.js';
import Dinners from './Dinners.js';
import AllDayBreakfast from './AllDayBreakfast.js';
import Salads from './Salads.js';
import Fries from './Fries.js';
import Desserts from './Desserts.js';
import Drinks from './Drinks.js';
import Alcohol from './Alcohol.js';
import Sandwiches from './Sandwiches.js';
import Burgers from './Burgers.js';
import Dogs from './Dogs.js';
import Wings from './Wings.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';
import {Animated} from "react-animated-css";
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import SunsetLogo from './SunsetLogo.png';
import { withRouter } from 'react-router-dom';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Kids from './Kids.js';
import Features from './Features.js';
import SunsetSwag from './SunsetSwag.js';


class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "Menu",
      showHideOrder: true,
      showHideAppetizers: true,
      showHideSalads: true,
      showHideFries: true,
      showHideSandwiches: true,
      showHideBurgers: true,
      showHideWings: true,
      showHideDogs: true,
      showHideLivDeets: false,
      ShowHideBack: false,
      
    };
    this.hideComponent = this.hideComponent.bind(this);
  }

  hideComponent(name) {
    switch (name) {
      case "Main":
        this.setState({ showHideAppetizers: true,
                        showHideSalads: true,
                        showHideFries: true,
                        showHideSandwiches: true,
                        showHideBurgers: true,
                        showHideWings: true,
                        showHideDogs: true,
                        showHideLivDeets: true,
                        showHideBack: false });
        break;
      case "Tori":
        this.setState({ showHideAppetizers: false,
                        showHideSalads: false,
                        showHideFries: false,
                        showHideSandwiches: false,
                        showHideBurgers: true,
                        showHideWings: false,
                        showHideDogs: false,
                        showHideLivDeets: false,
                        showHideBack: true });
        break;
      case "Tammy":
        this.setState({ showHideAppetizers: false,
                        showHideSalads: false,
                        showHideFries: false,
                        showHideSandwiches: false,
                        showHideBurgers: false,
                        showHideWings: true,
                        showHideDogs: false,
                        showHideLivDeets: false,
                        showHideBack: true });
        break;
      case "Lori":
        this.setState({ showHideAppetizers: false,
                        showHideSalads: false,
                        showHideFries: false,
                        showHideSandwiches: false,
                        showHideBurgers: false,
                        showHideWings: false,
                        showHideDogs: true,
                        showHideLivDeets: false,
                        showHideBack: true });
        break;
      case "Liv":
        this.setState({ showHideAppetizers: false,
                        showHideSalads: false,
                        showHideFries: false,
                        showHideSandwiches: false,
                        showHideBurgers: false,
                        showHideWings: false,
                        showHideDogs: false,
                        showHideLivDeets: true,
                        showHideBack: true });
        break;
    }
  }

  componentDidMount() {

  }

  componentWillUnmount() {
  }

  render() {
    const { showHideBack, showHideOrder, showHideAppetizers, showHideSalads, showHideFries, showHideSandwiches, showHideBurgers, showHideWings, showHideDogs, showHideLivDeets } = this.state;
    
    return (

      <Container fluid className="App-mainbg2">
        <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={this.props.showHideOrder}>      
          

          <Row className="MenuCont">
            <Col className="Main-card-bg">
<br/>
<br/>
<br/>
<br/>
                <Image src={SunsetLogo} className="TitleLogo"/>
                <br/>
                <p className="TitleFont2">
                  OUR MENU
                </p>
                <br/>
                
                <Button className="btn-order" target="_blank" href="https://order.tbdine.com/pickup/34012"> Order Online </Button>

                
               <br/>
              <br/>
            </Col>
      </Row>
      <Row className="checker-cont">
         </Row>
         <Row className="checker-cont2">
         </Row>
        </Animated>
        
        
        <Container style={{padding: 0, background: 'white'}} fluid>
        <br/>
          <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={this.props.showHideOrder}>
              <Row className="HoursCont2">  
                <Col className="Social-Icons">
                  
                  <Features state={this.state}/>
                  <Row className="Spacer"/>
                </Col>
                </Row>
              <Row className="HoursCont3">  
                <Col className="Social-Icons" md="6">
                    
                    {showHideAppetizers && (<Appetizers state={this.state}/>)}
                    <Row className="Spacer"/>
                    {showHideFries && (<Fries state={this.state}/>)}
                    <Row className="Spacer"/>
                    {showHideBurgers && (<Burgers state={this.state}/>)}
                    <Row className="Spacer"/>
                    <Dinners state={this.state}/>
                    <Row className="Spacer"/>
                    <AllDayBreakfast state={this.state}/>
                    <Row className="Spacer"/>
                  </Col>

                  <Col className="Social-Icons" md="6">

                      {showHideSalads && (<Salads state={this.state}/>)}
                      <Row className="Spacer"/>
                      {showHideSandwiches && (<Sandwiches state={this.state}/>)}
                      <Row className="Spacer"/>
                      {showHideDogs && (<Dogs state={this.state}/>)}
                      <Row className="Spacer"/>
                      {showHideWings && (<Wings state={this.state}/>)}
                      <Row className="Spacer"/>
                      <Desserts state={this.state}/>
                      <Row className="Spacer"/>
                  </Col>
                </Row>
                <Row className="HoursCont3">  
                <Col className="Social-Icons">
                <Kids state={this.state}/>
                      <Row className="Spacer"/>
                <Drinks state={this.state}/>
                      <Row className="Spacer"/>
                      <Alcohol state={this.state}/>
                      <Row className="Spacer"/>
                  <Row className="Spacer"/>
                </Col>
                </Row>

          </Animated>

        </Container>
        <Row className="checker-cont">
         </Row>
         <Row className="checker-cont2">
         </Row>
      </Container>
    );
  }
}

export default withRouter(Menu);
