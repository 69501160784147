import React from 'react';
import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';
import bolhuisMC from './bolhuisMCSunset.PNG';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import FB from './FB-glyph.png';
import CALL from './Call-Glyph.png';
import EM from './Email-Glyph.png';
import {Animated} from "react-animated-css";
import {AnimatedOnScroll} from "react-animated-css-onscroll";
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { CgMenuCheese } from "react-icons/cg";
import { withRouter } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import { IoLogoInstagram, IoLogoFacebook, IoFastFoodSharp, IoHome, IoRestaurantSharp } from 'react-icons/io5';
import {  IoMdCall, IoMdMail } from "react-icons/io";

class Home extends React.Component {

  constructor() {
    super();
    this.state = {
      name: "Home",
      showHideHome: true,
      showHideAboutUs: false,
      showHideContact: false,
      showHideServices: false,
      HomeActive: true,
      AboutUsActive: false,
      ServicesActive: false,
      ContactActive: false
    };
    this.hideComponent = this.hideComponent.bind(this);
  }

  hideComponent(name) {
    console.log(name);
    switch (name) {
      case "Home":
        this.setState({ showHideHome: true,
                        showHideAboutUs: false,
                        showHideContact: false,
                        showHideServices: false,
                        HomeActive: true,
                        AboutUsActive: false,
                        ServicesActive: false,
                        ContactActive: false });
        break;
      case "AboutUs":
        this.setState({ showHideAboutUs: true,
                        showHideHome: false,
                        showHideContact: false,
                        showHideServices: false,
                        HomeActive: false,
                        AboutUsActive: true,
                        ServicesActive: false,
                        ContactActive: false });
        break;
      case "Contact":
        this.setState({ showHideContact: true,
                        showHideHome: false,
                        showHideAboutUs: false,
                        showHideServices: false,
                        HomeActive: false,
                        AboutUsActive: false,
                        ServicesActive: false,
                        ContactActive: true });
        break;
      case "Services":
        this.setState({ showHideContact: false,
                        showHideHome: false,
                        showHideAboutUs: false,
                        showHideServices: true,
                        HomeActive: false,
                        AboutUsActive: false,
                        ServicesActive: true,
                        ContactActive: false });
        break;
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }


  render(){

    const { showHideHome, showHideAboutUs, showHideContact, showHideServices, HomeActive, AboutUsActive, ServicesActive, ContactActive } = this.state;
    return (
    <Container fluid className="App-mainbg2">
    <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={3000} animationOutDuration={3000} isVisible={showHideHome}>
      <Row>
        <Col>

          <Row className="SocialCont3">
            <Col className="Main-card-bg">
              <br/>
              <br/>
              <br/>
              <br/>
                <Image className="TitleLogo" src='https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/SunsetLogo.png?alt=media&token=220ede21-ea00-4d84-96c9-27a3696fe38d'/>
               <br/>
                  <p className="TitleFont2">
                    WELCOME!
                  </p>
                  <br/>
                    <Link to="/our-menu" style={{'textDecoration': 'none'}}>
                      <Button className="btn-order">View Our Menu!</Button>
                    </Link>
                  
               <br/>

              <br/>
            </Col>
          </Row>
        </Col>
      </Row>
         </Animated>
         <Row className="checker-cont">
         </Row>
         <Row className="checker-cont2">
         </Row>
         <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={3000} animationOutDuration={3000} isVisible={showHideHome}>
         
      <Row className="Card-body">
        <Col  xs>
        <AnimatedOnScroll animationIn="fadeIn" animationOut="fadeOut">
                      <Card className="Card-bg">
                        <Card.Body className="Card-body">
                          <Card.Title><h1 style={{'borderBottom':'2px solid rgba(255,233,0,1)', 'width': '80vw', 'paddingBottom':'25px', 'paddingTop':'25px'}}>About The Diner</h1></Card.Title>
                          <Card.Text className="Card-txt">
                            The Sunset Diner is an absolutely authentic diner at the 
                    far west end of St. Marys, on Queen Street West. Since the 
                    1950s, this diner has served four generations at the same 
                    location. There are some things that people expect when they visit a 
                    diner and the Sunset Diner does them all well! The all-day 
                    breakfast is a winner, and our burgers are 
                    excellent. And, don’t forget the dinner staples – fries, 
                    foot-longs and milkshakes, all consistently delicious!
                          </Card.Text>
                        </Card.Body>
                          
                        <br/>
                      </Card>

          
            </AnimatedOnScroll>
        </Col>
      </Row>
      <Row className="Card-body">
      <AnimatedOnScroll animationIn="fadeIn" animationOut="fadeOut">
        <Carousel fade="true" pause="hover" className="Carousel2">
                <Carousel.Item>
                      <Image className="CarouselImg" rounded src='https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/SunsetFront2.JPG?alt=media&token=8096a8b2-6c1b-4a3f-b324-3770a4e22d01'/>
                   
                </Carousel.Item>
                <Carousel.Item>
                      <Image className="CarouselImg" rounded src='https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Interior1.JPG?alt=media&token=d88009b1-0e1b-4f42-b739-2798178ddadc'/>
                   
                </Carousel.Item>
                <Carousel.Item>
                      <Image className="CarouselImg" rounded src='https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Interior2.JPG?alt=media&token=bf04582f-8eb3-40fc-b90c-476d55bd4993'/>
                </Carousel.Item>
                <Carousel.Item>
                      <Image className="CarouselImg" rounded src='https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Interior3.JPG?alt=media&token=2937535b-8f51-46b2-8403-063198b66ed0'/>
                </Carousel.Item>
                <Carousel.Item>
                      <Image className="CarouselImg" rounded src='https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Patio4.JPG?alt=media&token=ad8a6926-9845-4d9d-9138-c1d2fc958488'/>
                </Carousel.Item>
                <Carousel.Item>
                      <Image className="CarouselImg" rounded src='https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Patio5.JPG?alt=media&token=896a78aa-b9f5-4c4e-86c1-8f8fa1a7fb5c'/>
                </Carousel.Item>
                <Carousel.Item>
                      <Image className="CarouselImg" rounded src='https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Patio2.JPG?alt=media&token=b43ad2d1-5e80-4886-9449-6661083e28c1'/>
                </Carousel.Item>
        </Carousel>
        </AnimatedOnScroll>
        <br/>
      </Row>
      <Row className="Card-body">
        <Col  xs>
        <AnimatedOnScroll animationIn="fadeIn" animationOut="fadeOut">
                      <Card className="Card-bg">
                        <Card.Body className="Card-body">
                          <Card.Title><h1 style={{'borderBottom':'2px solid rgba(255,233,0,1)', 'width': '80vw', 'paddingBottom':'25px'}}>Social</h1></Card.Title>
                          <Row>
                  <Col sm={12} style={{ 'width': '100vw' }}>
                      <Button className="btn-servicedesc" target="_blank" href="https://www.facebook.com/thesunsetdiner/" variant="link"><IoLogoFacebook style={{'width':'80px', 'height':'80px', 'padding':'10px'}}/><h3> @thesunsetdiner</h3></Button>
                  </Col>
                          <Col sm={12} style={{ 'width': '100vw' }}>
                      <Button className="btn-servicedesc" target="_blank" href="https://www.instagram.com/sunsetdiner/" variant="link"><IoLogoInstagram style={{'width':'80px', 'height':'80px', 'padding':'10px'}}/><h3> @sunsetdiner</h3></Button>
                  </Col>

                  </Row>
                  <Row >
                         <Col sm={12} style={{ 'width': '100vw' }}>
                          <Button className="btn-servicedesc" variant="link" href="tel: +15192841040" ><IoMdCall style={{'width':'80px', 'height':'80px', 'padding':'10px'}}/><h3> 519-284-1040</h3></Button>
                          </Col>
                          <Col sm={12} style={{ 'width': '100vw' }}>
                          <Button className="btn-servicedesc" variant="link" href="mailto: sunsetdiner@gmail.com" ><IoMdMail style={{'width':'80px', 'height':'80px', 'padding':'10px'}}/><h3> sunsetdiner<br/>@gmail.com</h3></Button>
                          </Col>
                  </Row>
                        </Card.Body>
                          
                        <br/>
                      </Card>

          
            </AnimatedOnScroll>
        </Col>
      </Row>
      </Animated>
      <Row className="checker-cont">
         </Row>
         <Row className="checker-cont2">
         </Row>
    </Container>
    );
  }
}

export default withRouter(Home);

