import React from 'react';
import './App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';

import {Animated} from "react-animated-css";
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Card from 'react-bootstrap/Card';

import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import {db} from './firebase.js';
import MenuItem from './MenuItem.js';



class Features extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "Features",
      showHideFeatures: false,
      showHideBack: false
    }
    this.hideComponent = this.hideComponent.bind(this);
  }

  hideComponent(name) {
    console.log(name);
    switch (name) {
      case "Main":
        this.setState({ showHideFeatures: false,
                        showHideBack: false });
        break;
      case "Features":
        this.setState({ showHideFeatures: !this.state.showHideFeatures,
                        showHideBack: !this.state.showHideBack });
        break;
    }
  }

  addToCart = (product) => {
        let cart = localStorage.getItem('cart') 
                      ? JSON.parse(localStorage.getItem('cart')) : {};
        var length = Object.keys(cart).length - 1;
        var l = cart[0] ? JSON.stringify(parseInt(Object.keys(cart)[length]) + 1) : "0";
        cart[l] = (cart[l] ? cart[l]: {});
        cart[l] = product;
        
        localStorage.setItem('cart', JSON.stringify(cart));
      }

  componentDidMount() {
    db.collection("Features")
    .where("available","==",true)
    .get()
    .then(querySnapshot => {
      const data = querySnapshot.docs.map(doc => doc.data());
      console.log(data);
      this.setState({ products: data });

      var i = 0;
        querySnapshot.docs.forEach(document => {
          db.collection("Features").doc(document.id).collection("Ingredients")
        .get()
        .then(querySnapshot => {
          this.state.products[i].sizes = [];
          this.state.products[i].ingredients = querySnapshot.docs.map(doc => doc.data());
          i=i+1;
        });
          
        });

      var j = 0;
        querySnapshot.docs.forEach(document => {
          db.collection("Features").doc(document.id).collection("Sides")
        .get()
        .then(querySnapshot => {
          
          this.state.products[j].sides = querySnapshot.docs.map(doc => doc.data());
          j=j+1;
        });
          
        });

      var k = 0;
        querySnapshot.docs.forEach(document => {
          db.collection("Features").doc(document.id).collection("Eggs")
        .get()
        .then(querySnapshot => {
          
          this.state.products[k].eggs = querySnapshot.docs.map(doc => doc.data());
          k=k+1;
        });
          
        });

      var l = 0;
        querySnapshot.docs.forEach(document => {
          db.collection("Features").doc(document.id).collection("Meat")
        .get()
        .then(querySnapshot => {
          
          this.state.products[l].meat = querySnapshot.docs.map(doc => doc.data());
          l=l+1;
        });
          
        });

        var m = 0;
        querySnapshot.docs.forEach(document => {
          db.collection("Features").doc(document.id).collection("Flavours")
        .get()
        .then(querySnapshot => {
          
          this.state.products[m].flavours = querySnapshot.docs.map(doc => doc.data());
          m=m+1;
        });
          
        });

        var n = 0;
        querySnapshot.docs.forEach(document => {
          db.collection("Features").doc(document.id).collection("Patty")
        .get()
        .then(querySnapshot => {
          
          this.state.products[n].patty = querySnapshot.docs.map(doc => doc.data());
          n=n+1;
        });
          
        });
    });
  }

  componentWillUnmount() {
  }

  render() {
    const { showHideBack, showHideFeatures, products } = this.state;

    let date = new Date
    const day = date.getDay();
    
    return (

      <Container fluid>
        <Container fluid className="service-cont">
          <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={true}>
            <Col className="Menu-Cont">
              <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 250 }}
                  overlay={<Tooltip id="button-tooltip">
                              Give us a call at 519-284-1040
                            </Tooltip>}
                >
                    
                  <Button className="btn-number" href="tel: +15192841040">
                    Call us for our Daily Features
                  </Button>
                </OverlayTrigger>
            </Col>
          </Animated>
        </Container>
      </Container>
    );
  }
}

export default Features;
