import React from 'react';
import './App.css';
import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';
import {Animated} from "react-animated-css";
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import SunsetLogo from './SunsetLogo.png';
import { withRouter } from 'react-router-dom';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Slide } from 'react-slideshow-image';



class TV extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "Menu",
      showHideOrder: true,
      showHideAppetizers: true,
      showHideSalads: true,
      showHideFries: true,
      showHideSandwiches: true,
      showHideBurgers: true,
      showHideWings: true,
      showHideDogs: true,
      showHideLivDeets: false,
      ShowHideBack: false,
      slideImages: [
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202020-09-26%2C%209%2024%2017%20PM.jpg?alt=media&token=730a2ff3-df4a-4cbf-9eae-448ddeb97082',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202020-09-26%2C%209%2025%2013%20PM.jpg?alt=media&token=561234ed-6e58-4b64-afb8-f96f6ea41721',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-05-01%2C%2010%2054%2059%20AM.jpg?alt=media&token=c5903d39-e9a0-47f1-9168-94a94c8ef28b',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-05-01%2C%2011%2038%2001%20AM.jpg?alt=media&token=46fd5980-b346-4ccc-b055-14e2fd70dc41',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-05-01%2C%2011%2038%2006%20AM.jpg?alt=media&token=b5012682-f4a9-4d8e-af64-f1d22fb60969',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-05-05%2C%209%2003%2004%20PM.jpg?alt=media&token=9b9d66c5-61fb-49cb-98e2-711c71901146',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-05-05%2C%209%2003%2047%20PM.jpg?alt=media&token=9069c84a-b340-49b7-b4de-ca9848188205',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-05-09%2C%208%2031%2038%20PM.jpeg?alt=media&token=f1b27900-391e-49a7-89dd-bcb0dc72f7af',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-05-09%2C%208%2031%2047%20PM.jpg?alt=media&token=df2e3113-9193-4ab3-9adb-e7c817955aec',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-05-09%2C%208%2042%2005%20PM.jpg?alt=media&token=54599f39-364e-419d-a84f-670f3dc3395b',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-05-10%2C%208%2025%2040%20PM.jpg?alt=media&token=1b265a6a-5393-4235-a58f-ffe8a14f1c2a',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-05-10%2C%208%2026%2008%20PM.jpeg?alt=media&token=218bee1d-4db8-44fc-87f8-5b215d529abd',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-05-10%2C%208%2026%2030%20PM.jpeg?alt=media&token=6a5a5d35-3c06-478d-ad27-da81f09e2da6',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-05-10%2C%208%2035%2043%20PM.jpg?alt=media&token=6bb7c609-669c-43ee-bad6-ee2ceb493e46',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-05-10%2C%208%2036%2010%20PM.jpg?alt=media&token=3ec78473-7d4f-47ff-a753-9755db4d7c38',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-05-11%2C%207%2031%2050%20PM.jpg?alt=media&token=527c28b3-e158-4e18-b9df-d39a8a3923c0',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-05-11%2C%207%2034%2013%20PM.jpg?alt=media&token=795120de-1813-4d12-a372-2b2e533626db',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-05-11%2C%208%2005%2023%20PM.jpg?alt=media&token=5f6be708-b356-4c3a-8fca-ed9544495897',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-05-11%2C%208%2006%2010%20PM.jpg?alt=media&token=069074e3-e052-4c73-8506-d7663a7cde2d',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-05-13%2C%202%2004%2055%20PM.jpg?alt=media&token=166218f2-5bb8-4f97-ac77-f0b846d47616',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-05-16%2C%201%2048%2027%20PM.jpg?alt=media&token=9d847b56-344b-4666-8304-b1b0e9255cb2',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-05-16%2C%209%2036%2038%20AM.jpg?alt=media&token=6ccf0de4-7234-47b9-9dd7-7692f1647d1a',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-05-16%2C%209%2037%2005%20AM.jpg?alt=media&token=d95c4982-6089-49e8-9832-bf9fbee10612',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-05-16%2C%209%2037%2027%20AM.jpg?alt=media&token=18f08602-1fe7-47e0-af1e-5fe63e873de4',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-05-16%2C%209%2038%2010%20AM.jpg?alt=media&token=10c91887-69b9-4091-9b99-21bfe88b7031',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-05-16%2C%209%2051%2018%20AM.jpg?alt=media&token=23accb06-8091-43ba-88d8-a7b44377ae97',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-05-21%2C%201%2001%2011%20PM.jpg?alt=media&token=fc25fd55-3f6f-4840-9936-a46cd7dbf25b',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-05-29%2C%209%2030%2056%20AM.jpg?alt=media&token=6e54cef3-0e1e-483b-aece-f57f3ba5feed',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-06-01%2C%204%2002%2011%20PM.jpg?alt=media&token=5b0c72cd-4f49-4acc-8e6a-bc4361011c9f',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-06-02%2C%2010%2054%2059%20AM.jpg?alt=media&token=446451a3-da5b-4906-a8c0-b4f62cbbf1bf',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-06-02%2C%209%2012%2054%20AM.jpg?alt=media&token=3688c710-540b-479d-968d-418b967bd176',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-06-02%2C%209%2014%2030%20AM.jpg?alt=media&token=1a7a35dc-b1d3-4bf6-b201-c69f2144ff31',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-06-02%2C%209%2014%2049%20AM.jpg?alt=media&token=7bbc5081-35f1-40c0-ae1f-51c3e59ee5e9',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-06-02%2C%209%2026%2033%20AM.jpg?alt=media&token=a27f9775-5fcd-4f53-8a7a-01ebc959f31a',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-06-04%2C%209%2019%2010%20AM.jpg?alt=media&token=fd6d32e6-5609-4535-af5d-5103b48437b5',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-06-08%2C%2011%2045%2000%20AM.jpg?alt=media&token=4cc3b4be-433e-4e4c-a71c-3b364a430989',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-06-08%2C%2011%2045%2019%20AM.jpg?alt=media&token=3864448c-9951-47cf-b2df-414d24d39f6e',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-06-11%2C%208%2024%2030%20PM.jpeg?alt=media&token=900d6155-9c00-49e8-b7db-b912b929eb32',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-06-14%2C%208%2012%2026%20PM.jpg?alt=media&token=1e6a2c27-0ecb-4952-8fb8-ae5e6be2c52d',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-06-22%2C%209%2014%2003%20PM.jpg?alt=media&token=5525e4ed-06f0-4cea-945c-dc4c2a49736f',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-06-26%2C%204%2004%2027%20PM.jpg?alt=media&token=3ff17af3-b158-42ca-bd40-bc4356a51edb',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-06-26%2C%204%2008%2038%20PM%20(1).jpg?alt=media&token=0db44c3a-ff76-4102-836c-2d31685ff05d',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-06-26%2C%207%2002%2041%20PM.jpg?alt=media&token=2f745d86-e772-4fae-82db-70176feb12b3',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-06-27%2C%207%2021%2017%20PM.jpg?alt=media&token=c0a6a8c0-f4d1-4e81-a77a-6e37ac250a3a',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-06-29%2C%208%2019%2055%20PM.jpg?alt=media&token=af04ed7c-1852-4e52-ae13-2235ccf4a12b',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-06-30%2C%206%2024%2043%20PM.jpg?alt=media&token=079188a4-2644-4ff7-adac-f7376b3ffdab',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-06-30%2C%209%2013%2030%20PM.jpg?alt=media&token=ce73599f-e320-43cd-8d0d-2dbbabfc6908',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-06-30%2C%209%2035%2039%20PM.jpg?alt=media&token=9051446b-f762-44d0-b43a-3a7d9312653e',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-06-30%2C%209%2036%2058%20AM.jpg?alt=media&token=8dcf1eaa-815e-4a7b-8bb3-315645bafae4',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-01%2C%201%2050%2048%20PM.jpg?alt=media&token=a68e1148-dd25-49d8-9a4c-8c85301ffee3',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-03%2C%2012%2008%2031%20PM.jpg?alt=media&token=f5dcfb39-37a9-42fd-9b13-5124bd1c4769',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-03%2C%2012%2011%2008%20PM.jpg?alt=media&token=2a1d19cd-6c8e-4e56-945c-bcb26338f64b',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-03%2C%206%2044%2033%20PM.jpg?alt=media&token=c0825c12-1380-4a82-95cc-20f4e664cd4a',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-03%2C%206%2045%2034%20PM.jpg?alt=media&token=6c8081e1-2ef8-4d25-8f89-cc8135debc9d',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-03%2C%206%2056%2037%20PM.jpg?alt=media&token=e5160687-b58e-477d-a4a1-ea308825f143',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-03%2C%206%2056%2041%20PM.jpg?alt=media&token=0efaf250-b900-45a0-adb8-04411d547a81',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-03%2C%207%2014%2015%20PM.jpg?alt=media&token=4c4b77a1-0148-431b-b7be-7b028ca762ab',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-03%2C%207%2014%2053%20PM.jpg?alt=media&token=ad20ee96-265b-4816-8fc4-997ec824c08b',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-07%2C%2011%2002%2035%20AM.jpg?alt=media&token=145c1bf1-7aed-46a2-a620-a6d86dba6c8d',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-09%2C%2011%2054%2020%20AM.jpg?alt=media&token=cc07bf51-e89a-4a03-8ea3-27c6c9a37ce5',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-09%2C%2011%2054%2047%20AM.jpg?alt=media&token=4402dd36-7af7-4a25-aaed-4a860258c8f8',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-09%2C%2011%2057%2011%20AM.jpg?alt=media&token=1a7d14c3-a930-4318-8dbf-b09b4651b697',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-09%2C%2012%2001%2004%20PM.jpg?alt=media&token=d656242b-0190-4c25-931d-3d0c443de4fb',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-10%2C%209%2014%2052%20PM.jpeg?alt=media&token=3b6ef152-7339-4bc9-8c1a-6c6573c62223',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-10%2C%209%2016%2018%20PM.jpeg?alt=media&token=5016b081-7065-49ad-a252-698b9fc81bf0',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-10%2C%209%2017%2038%20PM.jpeg?alt=media&token=e4f4b75d-a5f9-471d-9c2d-ecd371300818',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-10%2C%209%2020%2018%20PM.jpg?alt=media&token=3b2e5af5-fe95-4341-8f0b-1a8c48fa391f',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-11%2C%208%2017%2033%20PM.jpg?alt=media&token=dbe1a4cb-3380-411b-9108-238291415197',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-11%2C%208%2026%2052%20PM.jpeg?alt=media&token=f69debd9-df38-40e7-b686-82483ae2619c',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-11%2C%208%2044%2025%20PM.jpg?alt=media&token=bdeedb69-88b4-4f2f-9255-8d9f9faa3351',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-11%2C%208%2045%2013%20PM.jpg?alt=media&token=f0038666-7472-4deb-b5aa-5536877d334f',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-11%2C%208%2045%2028%20PM.jpg?alt=media&token=0b9220ed-8c1a-441f-b756-97ba96f1dabf',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-11%2C%208%2045%2050%20PM.jpg?alt=media&token=409839e5-2cec-42b0-bb98-493e816f958f',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-11%2C%208%2046%2052%20PM.jpg?alt=media&token=e886b9af-66da-4d9c-9b6b-c919aab69613',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-12%2C%201%2040%2050%20PM.jpg?alt=media&token=3edb66b1-20e4-4417-adf9-902c45569f3f',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-12%2C%201%2043%2029%20PM.jpg?alt=media&token=c8e29e59-2a0a-4e23-a855-aad13dcbc15b',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-12%2C%201%2043%2040%20PM.jpg?alt=media&token=8edc58fe-1e0e-4c97-86e7-6489f041244b',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-12%2C%201%2044%2018%20PM.jpg?alt=media&token=55162cf4-f7e1-4813-95a6-aa958b8e2603',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-12%2C%201%2044%2023%20PM.jpg?alt=media&token=2b4cc105-e41f-4661-9d7f-44240b972e34',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-12%2C%201%2050%2022%20PM.jpg?alt=media&token=f90fabb4-5192-447a-9403-0ea62792a8eb',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-12%2C%201%2050%2026%20PM.jpg?alt=media&token=c8a12bcf-542b-43f6-ad68-048adece2db6',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-12%2C%201%2050%2046%20PM.jpg?alt=media&token=8215b497-2e06-492b-858b-77c2b7bfc287',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-12%2C%201%2050%2056%20PM.jpg?alt=media&token=79e26439-3b6e-4457-aadb-5ae07ffb2ca5',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-12%2C%201%2052%2030%20PM.jpg?alt=media&token=d1904d3d-c552-405b-ad95-1cbb18fb0921',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-12%2C%201%2052%2047%20PM.jpg?alt=media&token=0c403819-5915-4255-b696-e6f1eac9c15d',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-12%2C%201%2057%2014%20PM.jpg?alt=media&token=3fe60368-f294-4945-8678-15468d69d71e',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-12%2C%201%2059%2013%20PM.jpg?alt=media&token=6ce692d6-2bd2-468e-8d61-3e40aef2c7f1',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-12%2C%2010%2034%2007%20AM.jpg?alt=media&token=aa2d56ef-eada-4aa1-9d21-6abb0cf5d0e2',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-12%2C%2010%2037%2000%20AM.jpg?alt=media&token=27ead5c0-4820-4a74-adf7-0d3be4e5b6a5',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-12%2C%2011%2031%2048%20AM.jpg?alt=media&token=40a6795e-51c4-477d-8e14-d796ff725598',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-12%2C%202%2001%2051%20PM.jpg?alt=media&token=737f3184-7343-419d-9bcf-d8c618ac4fed',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-12%2C%202%2001%2055%20PM.jpg?alt=media&token=2cfccf2b-8872-4f97-8795-84ee52b3b150',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-12%2C%208%2021%2033%20PM.jpg?alt=media&token=c22b8c99-623f-487a-94b8-96c3dea20526',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-12%2C%209%2005%2009%20PM.jpeg?alt=media&token=765c14ef-634f-461c-9161-18f84ff2dcaf',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-12%2C%209%2005%2049%20PM.jpeg?alt=media&token=289e33ad-8c75-4492-85c0-b7afdbdb3cf3',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-12%2C%209%2006%2011%20PM.jpg?alt=media&token=c5bf05fa-0211-4073-8a9a-4230c6caddd3',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-12%2C%209%2006%2030%20PM.jpg?alt=media&token=5cb498b6-62a3-42c3-b9c0-aefb4a5571e4',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-12%2C%209%2006%2057%20PM.jpg?alt=media&token=d808d13b-1ff3-499e-906d-be02fc28b073',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-13%2C%207%2051%2057%20AM.jpeg?alt=media&token=c2e09ccc-15b2-44f3-9f9d-968243c0421e',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-13%2C%207%2056%2053%20AM.jpeg?alt=media&token=f416e406-bcc5-40f7-8db3-61dbb558e693',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-13%2C%209%2019%2045%20PM.jpeg?alt=media&token=2b771dd9-0285-4716-9629-39c888b0903a',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-14%2C%2011%2014%2052%20PM.jpg?alt=media&token=849099cb-4dce-4574-9eba-6d656ac114b9',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-14%2C%2012%2049%2047%20PM.jpg?alt=media&token=a978156a-82c6-4e5a-bd00-e290aecf6e93',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-14%2C%203%2044%2020%20PM.jpg?alt=media&token=e0f955e9-5c1a-40f5-9782-2428f25dd23a',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-14%2C%207%2044%2041%20PM.jpeg?alt=media&token=bc530e6c-4a3c-4491-b519-8dcec3a2512c',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-14%2C%207%2051%2035%20PM.jpg?alt=media&token=771969d9-908a-4004-b62c-b1199100027f',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-14%2C%207%2055%2017%20PM.jpeg?alt=media&token=96258c47-b323-4104-9064-0fa459e585dd',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-14%2C%208%2028%2002%20PM.jpeg?alt=media&token=73e520ec-53f5-4e68-a996-8d399d2ecffe',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-14%2C%208%2029%2015%20PM.jpeg?alt=media&token=decede78-5924-44cf-a0d2-f5d0b7669fce',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-14%2C%208%2029%2028%20PM.jpeg?alt=media&token=0757f05d-f23e-41f2-b367-81ade9f01097',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-14%2C%208%2041%2014%20PM.jpeg?alt=media&token=6ee4a6fc-3973-4cbf-881c-ea09f50456aa',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-20%2C%206%2022%2027%20PM.jpg?alt=media&token=99f68fc4-81b6-4721-9079-1dc77f927b0a',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-20%2C%207%2047%2043%20PM.jpg?alt=media&token=46fbd0aa-0975-4d92-94e9-b075045d0f70',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-20%2C%207%2053%2001%20PM.jpg?alt=media&token=e03337da-e099-4dc2-9efe-6998c6cd5880',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-20%2C%207%2057%2052%20AM.jpg?alt=media&token=d8583b2f-f706-44e9-b4b1-6b3bf43ff1af',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-20%2C%208%2012%2022%20PM.jpg?alt=media&token=d6e41fdb-4c32-4e9e-bd17-cdeb513a12dc',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-24%2C%2011%2014%2012%20AM.jpg?alt=media&token=ed566ff5-fa27-4535-b418-5aaadea869fa',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-25%2C%2010%2015%2034%20AM.jpeg?alt=media&token=838eff45-ea51-4ad1-ba9a-61a6c6c24cca',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FPhoto%202021-07-26%2C%207%2055%2003%20AM.jpg?alt=media&token=6c305d8d-62d7-4946-bb60-c5b81423941d',
        'https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/Sunset%20TV%2FVideo%202021-07-06%2C%208%2017%2059%20PM.mov?alt=media&token=d4f529f9-8709-40b8-8be1-fcb5ab282c09'

      ],
      
    };
    this.hideComponent = this.hideComponent.bind(this);
  }

  hideComponent(name) {
    switch (name) {
      case "Main":
        this.setState({ showHideAppetizers: true,
                        showHideSalads: true,
                        showHideFries: true,
                        showHideSandwiches: true,
                        showHideBurgers: true,
                        showHideWings: true,
                        showHideDogs: true,
                        showHideLivDeets: true,
                        showHideBack: false });
        break;
      case "Tori":
        this.setState({ showHideAppetizers: false,
                        showHideSalads: false,
                        showHideFries: false,
                        showHideSandwiches: false,
                        showHideBurgers: true,
                        showHideWings: false,
                        showHideDogs: false,
                        showHideLivDeets: false,
                        showHideBack: true });
        break;
      case "Tammy":
        this.setState({ showHideAppetizers: false,
                        showHideSalads: false,
                        showHideFries: false,
                        showHideSandwiches: false,
                        showHideBurgers: false,
                        showHideWings: true,
                        showHideDogs: false,
                        showHideLivDeets: false,
                        showHideBack: true });
        break;
      case "Lori":
        this.setState({ showHideAppetizers: false,
                        showHideSalads: false,
                        showHideFries: false,
                        showHideSandwiches: false,
                        showHideBurgers: false,
                        showHideWings: false,
                        showHideDogs: true,
                        showHideLivDeets: false,
                        showHideBack: true });
        break;
      case "Liv":
        this.setState({ showHideAppetizers: false,
                        showHideSalads: false,
                        showHideFries: false,
                        showHideSandwiches: false,
                        showHideBurgers: false,
                        showHideWings: false,
                        showHideDogs: false,
                        showHideLivDeets: true,
                        showHideBack: true });
        break;
    }
  }

  componentDidMount() {


  }

  componentWillUnmount() {
  }

  render() {
    const { slideImages, showHideBack, showHideOrder, showHideAppetizers, showHideSalads, showHideFries, showHideSandwiches, showHideBurgers, showHideWings, showHideDogs, showHideLivDeets } = this.state;
    
    return (

      <Container fluid className="App-mainbg4">

      
          <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={true}>
              
              <Row className="HoursCont4">  
              
                <Carousel interval={30000} slide={false} fade={true} controls={false} indicators={false} className="Carousel">
                 {
                    slideImages.map((image, index) => 
                    <Carousel.Item key={index}>
                  <Row style={{'width': 'auto', 'padding' : 0}}>

                      <Image rounded src={image} style={{'padding-left': 0, 'padding-right': 0, 'z-index': -10}} width="auto" height="590vh"/>

                  </Row>
                </Carousel.Item>
                
                      )
                 }

              </Carousel>
              <br/>
              <br/>
              <br/>
                </Row>
                <Row className="HoursCont4">
      <Col className="HoursCont5">
                      <Image rounded src='https://firebasestorage.googleapis.com/v0/b/sunset-diner-446b4.appspot.com/o/SunsetLogo.png?alt=media&token=220ede21-ea00-4d84-96c9-27a3696fe38d' width="20%" height="20%"/>
                      <p className="TitleFont4">
                    TV
                  </p>
                  </Col>
                  </Row>
                <br/>
          </Animated>

       
      </Container>
    );
  }
}

export default withRouter(TV);
