import firebase from "firebase";

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyAMr3bhQ-zoQmBepeP_gZQsrdF6sNmZtPc",
  authDomain: "sunset-diner-446b4.firebaseapp.com",
  databaseURL: "https://sunset-diner-446b4.firebaseio.com",
  projectId: "sunset-diner-446b4",
  storageBucket: "sunset-diner-446b4.appspot.com",
  messagingSenderId: "827318690206",
  appId: "1:827318690206:web:15cbda4865de6a6016b68f",
  measurementId: "G-BB94NLQ4ZZ"
});

const db = firebaseApp.firestore();


const auth = firebaseApp.auth();

const aut = firebaseApp.auth;


const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;

if (messaging != null){
messaging.usePublicVapidKey("BPAM--NCKQq87jUGNsE6aX0GIHjF4KCagFniLbylUV38Fy36k-l-4XPa-txq-ut9123kzCA1b1kwh56PnsLl8Zs");

messaging.onMessage(function(payload) {

    const notificationTitle = payload.notification.title;
    const notificationOptions = {
        body: payload.notification.body,
        icon: payload.notification.icon,        
    };

    if (!("Notification" in window)) {
        console.log("This browser does not support system notifications.");
    } else if (Notification.permission === "granted") {
        // If it's okay let's create a notification
        var notification = new Notification(notificationTitle,notificationOptions);
        notification.onclick = function(event) {
            event.preventDefault();
            window.open(payload.notification.click_action , '_blank');
            notification.close();
        }
    }

});}

export { db , auth , aut , messaging };