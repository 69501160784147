import React from 'react';
import './App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';
import {Animated} from "react-animated-css";
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Card from 'react-bootstrap/Card';

import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import {db} from './firebase.js';
import MenuItem from './MenuItem.js';
import Omelettes from './Omelettes.js';
import BreakfastSandwiches from './BreakfastSandwiches.js';
import FullBreakfasts from './FullBreakfasts.js';



class AllDayBreakfast extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "AllDayBreakfast",
      showHideAllDayBreakfast: false,
      showHideBack: false
    }
    this.hideComponent = this.hideComponent.bind(this);
  }

  hideComponent(name) {
    console.log(name);
    switch (name) {
      case "Main":
        this.setState({ showHideAllDayBreakfast: false,
                        showHideBack: false });
        break;
      case "AllDayBreakfast":
        this.setState({ showHideAllDayBreakfast: !this.state.showHideAllDayBreakfast,
                        showHideBack: !this.state.showHideBack });
        break;
    }
  }


  componentDidMount() {
    
  }

  componentWillUnmount() {
  }

  render() {
    const { showHideBack, showHideAllDayBreakfast } = this.state;
    
    return (

        <Container fluid className="service-cont">
          <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={true}>
            <Col className="service-mainbg">
              <Animated className="Menu-Cont" animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={this.props.showHideAllDayBreakfast}> 
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 250 }}
                  overlay={<Tooltip id="button-tooltip">
                              Tap to view our All Day Breakfast!
                            </Tooltip>}
                >
                    
                  <Button className="btn-number" onClick={() => this.hideComponent("AllDayBreakfast")}>
                    All Day Breakfast
                  </Button>
                </OverlayTrigger>
                {showHideAllDayBreakfast && (<Container fluid className="service-mainbg">
                <Row>
                <FullBreakfasts/>
                <Omelettes/>
                <BreakfastSandwiches/>
          {showHideBack && (<Button className="btn-primary" onClick={() => this.hideComponent("Main")}>Hide All Day Breakfast</Button>)}

        </Row>
        </Container>)}
              </Animated>
            </Col>
          </Animated>
        </Container>
    );
  }
}

export default AllDayBreakfast;
