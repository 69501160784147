import React from 'react';
import './App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';
import ListGroup from 'react-bootstrap/ListGroup';

import {Animated} from "react-animated-css";
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Card from 'react-bootstrap/Card';

import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import MenuModal from './MenuModal.js';
import Modal from 'react-bootstrap/Modal';
import { withRouter } from 'react-router-dom';
import { IoInformationCircleOutline } from 'react-icons/io5';


class MenuItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      price: 0.00,
      description: "",
      modalShow: false
    }
  }

  addToCart = (product) => {
        let cart = localStorage.getItem('cart') 
                      ? JSON.parse(localStorage.getItem('cart')) : {};
        var length = Object.keys(cart).length - 1;
        var l = cart[0] ? JSON.stringify(parseInt(Object.keys(cart)[length]) + 1) : "0";
        cart[l] = (cart[l] ? cart[l]: {});
        cart[l] = product;
        
        localStorage.setItem('cart', JSON.stringify(cart));
      }

    handleModalShowHide() {
        this.setState({ modalShow: !this.state.modalShow })
    }

  componentDidMount() {

    console.log(this.props.liquor);

    const liquor = this.props.liquor;

    const butter = this.props.butter;

    if (liquor==true) {
      console.log(liquor);
      this.setState({ liquor: liquor });
      console.log(this.state.liquor);
    }

    if (butter==true) {
      console.log(butter);
      this.setState({ butter: butter });
      console.log(this.state.liquor);
    }

    

  }

  componentWillUnmount() {
  }

  render() {

    const { name, price, description, addDesc, sideDesc, ingredients, sides, patty, flavours, toast, bread, eggs, steak, meat, meat2, alcohol, sizes, liquor, butter } = this.props;

    const { modalShow } = this.state;

    console.log(ingredients);

    console.log(liquor);
    
    return (
            <Container fluid>
              <Card className="ItemCard">
              
                <Row className="ItemDeets">
                  <Col className="Card-body"> 
                  <h3 className="ItemTitle">{name} - ${price}</h3>
                  <h4 className="ItemTitle">{sideDesc}</h4>
                    
                  </Col>
                  <Col xs="1" className="Card-body">
                  <Button variant="light" className="btn-primary2" onClick={() => this.handleModalShowHide()}><IoInformationCircleOutline className="btn-icon-black"/></Button>
                  </Col>
                </Row>
                <br/>
            </Card>
            {name ? (<MenuModal
              show={modalShow}
              onHide={() => this.handleModalShowHide()}
              name={name}
              price={price}
              description={description}
              addDesc={addDesc}
              sideDesc={sideDesc}
              ingredients={ingredients}
              sides={sides}
              patty={patty}
              flavours={flavours}
              toast={toast}
              butter={butter}
              bread={bread}
              eggs={eggs}
              steak={steak}
              meat={meat}
              meat2={meat2}
              alcohol={alcohol}
              sizes={sizes}
              liquor={liquor}
            />) : <Container fluid/>}
          </Container>
    );
  }
}

export default withRouter(MenuItem);
