import React from 'react';
import './App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';

import {Animated} from "react-animated-css";
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Card from 'react-bootstrap/Card';

import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import {db} from './firebase.js';
import MenuItem from './MenuItem.js';



class FullBreakfasts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "FullBreakfasts",
      showHideFullBreakfasts: false,
      showHideBack: false
    }
    this.hideComponent = this.hideComponent.bind(this);
  }

  hideComponent(name) {
    console.log(name);
    switch (name) {
      case "Main":
        this.setState({ showHideFullBreakfasts: false,
                        showHideBack: false });
        break;
      case "FullBreakfasts":
        this.setState({ showHideFullBreakfasts: !this.state.showHideFullBreakfasts,
                        showHideBack: !this.state.showHideBack });
        break;
    }
  }

  addToCart = (product) => {
        let cart = localStorage.getItem('cart') 
                      ? JSON.parse(localStorage.getItem('cart')) : {};
        var length = Object.keys(cart).length - 1;
        var l = cart[0] ? JSON.stringify(parseInt(Object.keys(cart)[length]) + 1) : "0";
        cart[l] = (cart[l] ? cart[l]: {});
        cart[l] = product;
        
        localStorage.setItem('cart', JSON.stringify(cart));
      }

  componentDidMount() {
    db.collection("AllDayBreakfast")
    .get()
    .then(querySnapshot => {
      const data = querySnapshot.docs.map(doc => doc.data());
      console.log(data);
      this.setState({ products: data });

      var i = 0;
        querySnapshot.docs.forEach(document => {
          db.collection("AllDayBreakfast").doc(document.id).collection("Ingredients")
        .get()
        .then(querySnapshot => {
          this.state.products[i].flavours = [];
          this.state.products[i].patty = [];
          this.state.products[i].sizes = [];
          this.state.products[i].ingredients = querySnapshot.docs.map(doc => doc.data());
          i=i+1;
        });
          
        });

      var j = 0;
        querySnapshot.docs.forEach(document => {
          db.collection("AllDayBreakfast").doc(document.id).collection("Sides")
        .get()
        .then(querySnapshot => {
          
          this.state.products[j].sides = querySnapshot.docs.map(doc => doc.data());
          j=j+1;
        });
          
        });

      var k = 0;
        querySnapshot.docs.forEach(document => {
          db.collection("AllDayBreakfast").doc(document.id).collection("Eggs")
        .get()
        .then(querySnapshot => {
          
          this.state.products[k].eggs = querySnapshot.docs.map(doc => doc.data());
          k=k+1;
        });
          
        });

      var l = 0;
        querySnapshot.docs.forEach(document => {
          db.collection("AllDayBreakfast").doc(document.id).collection("Meat")
        .get()
        .then(querySnapshot => {
          
          this.state.products[l].meat = querySnapshot.docs.map(doc => doc.data());
          l=l+1;
        });
          
        });

        let date = new Date
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'p.m.' : 'a.m.';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var today = hours + ':' + minutes + ' ' + ampm;
    console.log(today);

    /*if (hours >= 11 || ampm=='p.m.'){
    const products = this.state.products;    
    var i = 0;
    for (i;i<products.length;i++){
      products[i].price = products[i].price + 2;
    }
    i=0;
    this.setState({products: products});
  }*/
    });


  }

  formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'p.m.' : 'a.m.';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

  componentWillUnmount() {
    

}

  render() {
    const { showHideBack, showHideFullBreakfasts, products } = this.state;
    
    return (

      <Container fluid>
        <Container fluid className="service-cont">
          <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={true}>
            <Col className="service-mainbg">
              <Animated className="Menu-Cont" animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={this.props.showHideFullBreakfasts}> 
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 250 }}
                  overlay={<Tooltip id="button-tooltip">
                              Tap to view our Full Breakfast Meals!
                            </Tooltip>}
                >
                    
                  <Button className="btn-number" onClick={() => this.hideComponent("FullBreakfasts")}>
                    Full Breakfast Meals
                  </Button>
                </OverlayTrigger>
                {showHideFullBreakfasts && (<Container fluid className="service-mainbg">
                  {
                    products.map((product, index) =>
                      <MenuItem 
                        key={index} 
                        sides={product.sides} 
                        steak={product.steak} 
                        meat={product.meat} 
                        meat2={product.meat2} 
                        eggs={product.eggs} 
                        patty={product.patty}
                        flavours={product.flavours} 
                        bread={product.bread} 
                        toast={product.toast} 
                        butter={product.butter}
                        ingredients={product.ingredients} 
                        name={product.name} 
                        price={Math.round((product.price)*100)/100} 
                        description={product.description} 
                        addDesc={product.addDesc} 
                        sideDesc={product.sideDesc}
                        sizes={product.sizes}/>)
                  }


                <Row>
          {showHideBack && (<Button className="btn-primary" onClick={() => this.hideComponent("Main")}>Hide Full Breakfast Meals</Button>)}

        </Row>
        </Container>)}
              </Animated>
            </Col>
          </Animated>
        </Container>
      </Container>
    );
  }
}

export default FullBreakfasts;
