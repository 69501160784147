import React from 'react';
import './App.css';
import Appetizers from './Appetizers.js';
import Dinners from './Dinners.js';
import AllDayBreakfast from './AllDayBreakfast.js';
import Salads from './Salads.js';
import Fries from './Fries.js';
import Desserts from './Desserts.js';
import Drinks from './Drinks.js';
import Alcohol from './Alcohol.js';
import Sandwiches from './Sandwiches.js';
import Burgers from './Burgers.js';
import Dogs from './Dogs.js';
import Wings from './Wings.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';
import ListGroup from 'react-bootstrap/ListGroup';
import {Animated} from "react-animated-css";
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import SunsetLogo from './SunsetLogo.png';
import Form from 'react-bootstrap/Form';
import {db} from './firebase.js';
import Spinner from 'react-bootstrap/Spinner';


class Loading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
    this.hideComponent = this.hideComponent.bind(this);
  }

  hideComponent(name) {
    console.log(name);
    var tmptot = this.state.delivery ? 5 : 0;
    switch (name) {
      case "Pickup":
        this.setState({ pickup: true,
                        delivery: false,
                        cash: false,
                        credit: false,
                        total: this.state.total - tmptot
                        });
        break;
      case "Delivery":
        this.setState({ pickup: false,
                        delivery: true,
                        cash: false,
                        credit: false,
                        total: this.state.total + 5
                        });
        break;
      case "Cash":
        this.setState({ cash: true,
                        credit: false,
                        });
        break;
      case "Credit":
        this.setState({ cash: false,
                        credit: true,
                        });
        break;

    }
  }



  componentDidMount() {

  }

  componentWillUnmount() {

  }

  render() {
    var { showHideLoading } = this.state;
    
    const { cart, subtotal, hst, total, pickup, delivery, cash, credit } = this.state;

    

    return (

      <Container fluid>
        <Row className="Social-Icons">
          <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={showHideLoading}>
            <Spinner animation="grow" variant="light" />
          </Animated>
        </Row>
      </Container>
    );
  }
}

export default Loading;
